<template>
  <div class="pl-wrapper h-100">
    <Shared
      title="Hey partner name, Welcome!"
      subTitle="Please provide your Logo symbol so we can create your dedicated Studio."
      :btnDisabled="logo === ''"
      :param="paramObj"
    >
      <div class="pl-wrapper__content">
        <div class="pl-content__dd">
          <LxpDragDrop
            dragDropClass="ls-create__upload"
            :accept="['png']"
            variant="variant1"
            :editBtnContent="editBtnContent"
            :authToken="`Bearer ${$keycloak.token}`"
            :fileUploadUrl="getUploadUrl()"
            noteText="Recommended image specs: .png, 1MB max, 128x128px."
            @file-url="onLogoChange"
            @remove-file="onRemoveFile"
            id="partner-logo"
            v-model="logo"
          >
            <template v-slot:content>
              <div class="lxp-dd__drop--default">
                <img
                  src="@/assets/images/layout/onboarding-layout/content/add-logo.svg"
                  alt="add-logo"
                />
                <div class="lxp-dd__drop--default--content">
                  Drag and drop Logo symbol here or
                  <label
                    for="lxp-file-input-partner-logo"
                    class="lxp-dd__drop--default-select"
                    >Select image</label
                  >
                </div>
              </div>
            </template>
          </LxpDragDrop>
        </div>
        <div class="pl-content__preview">
          <div class="plc-preview__div">
            <img
              :src="
                logo
                  ? logo
                  : require('@/assets/images/layout/onboarding-layout/content/logo.svg')
              "
              alt="default logo"
              width="40"
              height="40"
            />
            <div class="plc-preview__partner">
              <h6>{{ userInfo.partner.org_short_name }}</h6>
              <p>Studio</p>
            </div>
          </div>
          <span class="plc__quick">Quick Preview</span>
        </div>
      </div>
    </Shared>
  </div>
</template>

<script>
import { LxpDragDrop } from "didactica";
import Shared from "./Shared.vue";
import { mapGetters } from "vuex";
export default {
  components: { LxpDragDrop, Shared },
  data() {
    return {
      logo: ""
    };
  },
  computed: {
    ...mapGetters(["allConfig", "userInfo"]),
    editBtnContent() {
      return `<div class="pl-content__dd--edit">
      <img src="${require("@/assets/images/partner/user-permissions/edit.svg")}" alt="edit"/>
      Edit
      </div>`;
    },
    paramObj() {
      return { logo: this.logo };
    }
  },
  methods: {
    getUploadUrl() {
      return `${this.allConfig.baseURL}/spaces/upload-image/`;
    },
    onRemoveFile() {
      this.logo = "";
    },
    onLogoChange(e) {
      this.logo = e.image;
    }
  }
};
</script>
<style lang="scss" scoped>
.pl-wrapper {
  .pl-wrapper__content {
    @include flex-horizontal-center;
    .pl-content__dd {
      width: 174px;
      .lxp-dd__drop--default--content {
        @include label-large;
      }
    }
    .pl-content__preview {
      margin-left: 48px;
      @include center;
      .plc-preview__div {
        padding: 32px;
        border-radius: 8px;
        width: 198px;
        height: 148px;
        background: $brand-neutral-0;
        border: 1px solid $brand-neutral-200;
        display: flex;
        align-items: center;
        img {
          border-radius: 100px;
          object-fit: cover;
        }
        .plc-preview__partner {
          padding: 0 8px;
          h6 {
            @include subtitle-regular;
            color: $brand-neutral-900;
            margin-bottom: 0;
          }
          p {
            @include label-large($brand-neutral-900);
            margin-bottom: 0;
          }
        }
      }
      .plc__quick {
        @include label-medium;
        color: $brand-neutral-300;
        display: flex;
        margin: 8px 0;
        text-align: left;
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
.ls-create__upload {
  padding: 16px 24px !important;
  height: 148px !important;
  &.lxp-dd__error {
    padding: 16px 12px !important;
  }
  .lxp-dd__drop--default {
    text-align: center;
  }
  .lxp-dd__error--div {
    .lxp-dd__error--variant {
      img {
        width: 30px;
      }
      .lxp-dd__error--content {
        @include label-large;
        margin-top: 7px;
      }
    }
  }
}
.pl-content__dd--edit {
  @include label-large($brand-primary, 500);
}
</style>
