<template>
  <div class="onboarding h-100">
    <onboarding-layout :steps="steps">
      <b-container class="ob-main">
        <b-row class="justify-content-md-center h-100">
          <b-col align-self="center" class="h-100">
            <PartnerLogo />
          </b-col>
        </b-row>
      </b-container>
    </onboarding-layout>
  </div>
</template>

<script>
import OnboardingLayout from "@/components/Layout/OnboardingLayout.vue";
import PartnerLogo from "@/components/Onboarding/PartnerLogo.vue";

export default {
  components: {
    OnboardingLayout,
    PartnerLogo
  },
  data() {
    return {
      steps: [
        {
          name: "Visual Identity",
          active: true,
          complete: false
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.ob-main {
  height: calc(100% - 103px);
}
</style>
