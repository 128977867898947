<template>
  <div class="ol-wrapper h-100">
    <Header :onboardingSteps="steps" />
    <div class="ol-wrapper__main">
      <slot></slot>
    </div>
    <div class="ol-wrapper__footer">
      <p>WHO Academy &copy; {{ new Date().getFullYear() }}</p>
    </div>
  </div>
</template>

<script>
import Header from "./OnboardingLayout/Header.vue";
export default {
  props: {
    steps: {
      type: Array,
      required: true
    }
  },
  components: {
    Header
  }
};
</script>

<style lang="scss" scoped>
.ol-wrapper {
  .ol-wrapper__main {
    height: calc(100% - 150px);
  }
  .ol-wrapper__footer {
    p {
      text-align: right;
      @include label-medium;
      color: $brand-neutral-700;
      margin-right: 20px;
    }
  }
}
</style>
