<template>
  <div class="ob-shared">
    <div class="ob-main__heading text-center">
      <h5>{{ title }}</h5>
      <p>
        {{ subTitle }}
      </p>
    </div>
    <slot></slot>
    <div class="ob-shared__footer">
      <LxpButton
        variant="secondary1"
        size="default"
        :disabled="btnDisabled"
        @click="onNext"
        ><p class="mb-0">
          Next
          <img
            :src="
              btnDisabled
                ? require('@/assets/images/layout/onboarding-layout/header/checkmark-grey.svg')
                : require('@/assets/images/layout/onboarding-layout/header/checkmark.svg')
            "
            alt="checkmark"
          /></p
      ></LxpButton>
      <span class="pl-footer__note">Please enter &#9166; to continue</span>
    </div>
  </div>
</template>

<script>
import { LxpButton } from "didactica";
export default {
  components: {
    LxpButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    btnDisabled: {
      type: Boolean,
      default: false
    },
    param: {
      type: [Array, Object, String]
    }
  },
  methods: {
    onNext() {
      this.$store.dispatch("updatePartnerLogo", this.param).then(() => {
        this.$store.dispatch("getUserSummary").then(() => {
          this.$router.push({ name: "Home" });
        });
        sessionStorage.setItem("new_user_alert", true);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ob-shared {
  height: 100%;
  @include center;
  justify-content: flex-start;
  .ob-main__heading {
    margin-bottom: 8%;
    h5 {
      @include h5;
      color: $brand-neutral-900;
      margin-bottom: 8px;
    }
    p {
      @include body-regular;
      margin-bottom: 0;
      padding: 0 9%;
    }
  }
  .ob-shared__footer {
    margin-top: 8%;
    @include center;
    span {
      display: inline-flex;
      margin-top: 9px;
      @include label-large($brand-neutral-300);
    }
  }
}
</style>
