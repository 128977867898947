var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ol-header"},[(false)?_c('LxpProgress',{attrs:{"variant":"danger","value":_vm.progressVal,"showProgressValue":false}}):_vm._e(),_c('div',{staticClass:"ol-header__main"},[_vm._m(0),(_vm.onboardingSteps.length > 0 && false)?_c('div',{staticClass:"ol-header__content"},_vm._l((_vm.onboardingSteps),function(os,i){return _c('div',{key:i,class:[
          'ol-header__content--steps',
          { active: os.active, complete: os.complete }
        ]},[_c('p',[_vm._v(_vm._s(os.name))]),_c('span',{class:[
            'ol-header__content--steps__round',
            { complete: os.complete }
          ]},[(os.complete)?_c('span',{staticClass:"ol-steps__complete"},[_c('img',{attrs:{"src":require("@/assets/images/layout/onboarding-layout/header/checkmark.svg"),"alt":"checkmark","width":"17"}})]):(os.active)?_c('span',{staticClass:"ol-steps__count"},[_vm._v(_vm._s(i + 1))]):_vm._e()])])}),0):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ol-header__img"},[_c('img',{attrs:{"src":require("@/assets/images/layout/onboarding-layout/header/whoa-logo.svg"),"alt":"whoa-logo","width":"98"}})])
}]

export { render, staticRenderFns }