<template>
  <div class="ol-header">
    <LxpProgress
      variant="danger"
      :value="progressVal"
      :showProgressValue="false"
      v-if="false"
    />
    <div class="ol-header__main">
      <div class="ol-header__img">
        <img
          src="@/assets/images/layout/onboarding-layout/header/whoa-logo.svg"
          alt="whoa-logo"
          width="98"
        />
      </div>
      <div
        class="ol-header__content"
        v-if="onboardingSteps.length > 0 && false"
      >
        <div
          :class="[
            'ol-header__content--steps',
            { active: os.active, complete: os.complete }
          ]"
          v-for="(os, i) in onboardingSteps"
          :key="i"
        >
          <p>{{ os.name }}</p>
          <span
            :class="[
              'ol-header__content--steps__round',
              { complete: os.complete }
            ]"
          >
            <span class="ol-steps__complete" v-if="os.complete">
              <img
                src="@/assets/images/layout/onboarding-layout/header/checkmark.svg"
                alt="checkmark"
                width="17"
              />
            </span>
            <span class="ol-steps__count" v-else-if="os.active">{{
              i + 1
            }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LxpProgress } from "didactica";
export default {
  props: {
    onboardingSteps: {
      type: Array,
      default: () => []
    }
  },
  components: {
    LxpProgress
  },
  computed: {
    progressVal() {
      return 20;
    }
  }
};
</script>

<style lang="scss" scoped>
.ol-header {
  margin-bottom: 32px;
  .ol-header__main {
    margin-top: 20px;
    padding: 0 42px;
    display: flex;
    .ol-header__content {
      @include flex-horizontal-center;
      flex-grow: 1;
      .ol-header__content--steps {
        display: flex;
        align-items: center;
        margin-right: 32px;
        &.active {
          p {
            color: $brand-secondary1-400;
          }
          .ol-header__content--steps__round {
            border-color: $brand-secondary1-400;
            background: $brand-secondary1-400;

            .ol-steps__count {
              color: $brand-primary-50;
              font-size: 11px;
              font-weight: 500;
              line-height: 16.5px;
              letter-spacing: 0.5px;
            }
            .ol-steps__complete {
              display: flex;
            }
          }
        }
        &.complete {
          .ol-header__content--steps__round {
            border-color: $brand-secondary1-100;
            background: $brand-secondary1-100;
          }
        }
        p {
          @include label-medium;
          margin: 0 4px 0 0;
          color: $brand-neutral-300;
        }
        .ol-header__content--steps__round {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 17px;
          height: 17px;
          border: 2px solid #999999;
          border-radius: 100px;
        }
      }
    }
  }
}
</style>
